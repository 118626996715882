const ConnectionFailedComponent = import(
  'src/views/errors/ConnectionFailedView'
);

const componentLoader: any = (lazyComponent, attemptsLeft) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(component => resolve(component))
      .catch(error => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            resolve(ConnectionFailedComponent);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
};

export default componentLoader;
