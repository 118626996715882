import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
import componentLoader from './utils/componentLoader';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any[];
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => {
              const Child = () => (
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              );
              if (!!route?.guard?.length) {
                return route?.guard?.map((Guard: any) => (
                  <Guard>
                    <Child />
                  </Guard>
                ));
              } else {
                return (
                  <Fragment>
                    <Child />
                  </Fragment>
                );
              }
            }}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: lazy(() => import('src/views/privacy-policy'))
  },
  {
    exact: true,
    path: '/terms-of-use',
    component: lazy(() => import('src/views/TermsAndConditions'))
  },
  {
    exact: true,
    path: '/confirm-email/:hash',
    component: lazy(() => import('src/views/auth/ConfirmEmailView'))
  },
  {
    exact: true,
    path: '/password-reset/:hash',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: [GuestGuard],
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/payment/success',
    component: lazy(() => import('src/common/pages/SuccessPage'))
  },
  {
    exact: true,
    path: '/payment/failed',
    component: lazy(() => import('src/common/pages/FailedPage'))
  },
  {
    exact: true,
    path: '/payment/cancelled',
    component: lazy(() => import('src/common/pages/CancelledPage'))
  },
  {
    path: '/app',
    guard: [AuthGuard],
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/management/dashboard',
        component: lazy(() => import('src/views/dashboard/DashboardView'))
      },
      {
        exact: true,
        path: '/app/management/account/:tab?',
        component: lazy(() => import('src/views/user/UserListView'))
      },
      {
        exact: true,
        path: '/app/management/role',
        component: lazy(() => import('src/views/role/RoleListView'))
      },
      {
        exact: true,
        path: '/app/management/company',
        component: lazy(() => import('src/views/company/CompanyListView'))
      },
      {
        exact: true,
        path: '/app/management/promo',
        component: lazy(() =>
          componentLoader(() => import('src/views/promo/PromoListView'), 3)
        )
      },
      {
        exact: true,
        path: '/app/management/promo/add',
        component: lazy(() => import('src/views/promo/PromoEditor'))
      },
      {
        exact: true,
        path: '/app/management/promo/:id/edit',
        component: lazy(() => import('src/views/promo/PromoEditor'))
      },
      {
        exact: true,
        path: '/app/management/promo/:id/view',
        component: lazy(() => import('src/views/promo/PromoEditor'))
      },
      {
        exact: true,
        path: '/app/management/webhooks',
        component: lazy(() => import('src/views/webhook/WebhookListView'))
      },
      {
        exact: true,
        path: '/app/management/webhook/add',
        component: lazy(() => import('src/views/webhook/WebhookEditor'))
      },
      {
        exact: true,
        path: '/app/management/webhook/:id/edit',
        component: lazy(() => import('src/views/webhook/WebhookEditor'))
      },
      {
        exact: true,
        path: '/app/management/system/settings',
        component: lazy(() => import('src/views/settings/SettingsView'))
      },
      {
        exact: true,
        path: '/app/management/system/variables',
        component: lazy(() => import('src/views/variables/VariablesView'))
      },
      {
        exact: true,
        path: '/app/management/system/variables/add',
        component: lazy(() => import('src/views/variables/VariablesEditor'))
      },
      {
        exact: true,
        path: '/app/management/system/variables/:id/edit',
        component: lazy(() => import('src/views/variables/VariablesEditor'))
      },
      {
        exact: true,
        path: '/app/management/events',
        component: lazy(() => import('src/views/events/EventsView'))
      },
      {
        exact: true,
        path: '/app/management/events/add',
        component: lazy(() => import('src/views/events/EventsEditor'))
      },
      {
        exact: true,
        path: '/app/management/events/:id/edit',
        component: lazy(() => import('src/views/events/EventsEditor'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
