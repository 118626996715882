/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from '@mui/icons-material/Store';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CampaignIcon from '@mui/icons-material/Campaign';
import LandscapeIcon from '@mui/icons-material/Landscape';
import SportsIcon from '@mui/icons-material/Sports';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import WebhookIcon from '@mui/icons-material/Webhook';
import DashboardIcon from 'src/assets/icons/DashboardIcon';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Management',
    items: [
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        href: '/app/management/dashboard'
      },
      {
        title: 'Accounts',
        icon: GroupIcon,
        href: '/app/management/account'
      },
      {
        title: 'Roles',
        icon: ManageAccountsIcon,
        href: '/app/management/role'
      },
      {
        title: 'Company',
        icon: StoreIcon,
        href: '/app/management/company'
      },
      {
        title: 'Promo',
        icon: CampaignIcon,
        href: '/app/management/promo'
      },
      {
        title: 'Events',
        icon: CalendarMonthIcon,
        href: '/app/management/events'
      },
      // {
      //   title: 'Ads Management',
      //   icon: NewspaperIcon,
      //   href: '/app/management/ads',
      // },
      // {
      //   title: 'Nature',
      //   icon: LandscapeIcon,
      //   href: '/app/management/nature',
      // },
      // {
      //   title: 'Supplier',
      //   icon: LocalShippingIcon,
      //   href: '/app/management/supplier',
      // },
      // {
      //   title: 'Trainers',
      //   icon: SportsIcon,
      //   href: '/app/management/trainer',
      // },
      // {
      //   title: 'Work Days',
      //   icon: CalendarMonthIcon,
      //   href: '/app/management/work-day',
      // },
      // {
      //   title: 'Work Hours',
      //   icon: AccessTimeIcon,
      //   href: '/app/management/work-hour',
      // },
      {
        title: 'Webhooks',
        icon: WebhookIcon,
        href: '/app/management/webhooks'
      },
      {
        title: 'System',
        icon: SettingsIcon,
        items: [
          { title: 'Variables', href: '/app/management/system/variables' },
          { title: 'Settings', href: '/app/management/system/settings' }
        ]
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                // src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.firstName} {user.lastName}
            </Link>
            <Typography variant="body2" color="textSecondary">
              Admin
              {/* {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link> */}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
