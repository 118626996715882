import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// Your SVG path data
const customIconPath = (
  <path d="M2 21.25v-1.5h20v1.5H2Zm1-3V11.5h2.5v6.75H3Zm5.15 0V6.5h2.5v11.75h-2.5Zm5.175 0V9.5h2.5v8.75h-2.5Zm5.175 0V3.5H21v14.75h-2.5Z" />
);

// CustomIcon component using SvgIcon
const DashboardIcon = SvgIconProps => (
  <SvgIcon {...SvgIconProps}>{customIconPath}</SvgIcon>
);

export default DashboardIcon;
