import React, { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { StylesProvider, styled } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import NotificationManagerProvider from './shared/utils/Notification/NotificationManager';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-default': {
    flexWrap: 'nowrap',
    fontFamily: 'Roboto'
  },
  '&.notistack-MuiContent-success': {
    flexWrap: 'nowrap',
    fontFamily: 'Roboto'
  },
  '&.notistack-MuiContent-error': {
    flexWrap: 'nowrap',
    fontFamily: 'Roboto'
  }
}));

const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider
              Components={{
                default: StyledMaterialDesignContent,
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent
              }}
              dense
              maxSnack={3}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <NotificationManagerProvider>
                <Router history={history}>
                  <AuthProvider>
                    <GlobalStyles />
                    <ScrollReset />
                    {renderRoutes(routes)}
                  </AuthProvider>
                </Router>
              </NotificationManagerProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
