import React, { createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const NotificationContext = createContext(null);

export const useNotification = () => useContext(NotificationContext);

const NotificationManagerProvider = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = (variant, { message, duration }) => {
    enqueueSnackbar(message, {
      variant: variant,
      action: snackbarId => (
        <IconButton onClick={() => closeSnackbar(snackbarId)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      autoHideDuration: duration
    });
  };

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationManagerProvider;
